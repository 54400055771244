import React, {useContext, useState} from "react";
import './login.css'
import {useNavigate} from "react-router-dom";
import {signInWithEmailAndPassword} from "firebase/auth";
import {auth} from '../../Firebase'
import {useAuth} from "../../AuthContext";

const Login = () => {
    const {login,} = useAuth();
    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')

    const handleLogin = (e) => {
        e.preventDefault();

        if (email.trim() === "" || password.trim() === "") {
            return;
        }
        signInWithEmailAndPassword(auth, email, password)
            .then((credentials) => {
                login(credentials.user)
            })
            .catch((err) => {
                console.log(err)
            });
    };

    return (
        <div>
            <h1 className='adminPanel'>Admin Panel</h1>
            <div className="container">
                <h2>Login Page</h2>
                <form onSubmit={handleLogin}>
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <button type="submit" onClick={handleLogin}>Login</button>
                </form>
            </div>
        </div>
    )
}

export default Login