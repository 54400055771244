import './App.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Login from './Pages/LoginPage/Login';
import Home from './Pages/HomePage/Home';
import PrivateRoute from "./PrivateRoute";
import {AuthProvider} from "./AuthContext";

function App() {
    return (
        <div className="App">
            <Router>
                <AuthProvider>

                    <Routes>
                        <Route path="/login" element={<Login/>}/>
                        <Route path="/" element={<PrivateRoute><Home/></PrivateRoute>}/>
                        <Route path="*" element={<PrivateRoute><Home/></PrivateRoute>}/>
                    </Routes>

                </AuthProvider>
            </Router>
        </div>
    );
}

export default App;
