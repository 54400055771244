import React, { useState, useEffect } from 'react';
import {
    collection,
    getDocs,
    orderBy,
    query,
    onSnapshot,
    doc,
    updateDoc,
} from 'firebase/firestore';
import { db } from '../../../Firebase';
import './ViewCard.css';

const ViewCard = () => {
    const [user, setUser] = useState([]);
    const [status, setStatus] = useState({});
    const [searchSubject, setSearchSubject] = useState('');
    const [filter, setFilter] = useState('all');
    const userCollection = collection(db, 'feedback');

    const getUser = async () => {
        const q = query(userCollection, orderBy('timestamp', 'desc'));
        const querySnapshot = await getDocs(q);
        const data = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
            time: doc.data().timestamp?.toDate().toString().slice(4, 24),
        }));
        setUser(data);
    };

    useEffect(() => {
        getUser();
    }, []);

    useEffect(() => {
        const unsubscribe = onSnapshot(userCollection, (querySnapshot) => {
            const updatedStatus = {};
            querySnapshot.forEach((doc) => {
                updatedStatus[doc.id] = doc.data().status || 'unviewed';
            });
            setStatus(updatedStatus);
        });

        return () => {
            unsubscribe();
        };
    }, []);

    const handleStatusChange = async (event, userId) => {
        const newStatus = { ...status };
        newStatus[userId] = event.target.value;
        setStatus(newStatus);

        try {
            const userDocRef = doc(db, 'feedback', userId);
            await updateDoc(userDocRef, { status: event.target.value });
        } catch (error) {
            console.log('Error updating status:', error);
        }
    };

    const handleSearchChange = (event) => {
        setSearchSubject(event.target.value);
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const filteredUsers = user.filter((userData) => {
        if (filter === 'all') {
            return userData.subject.toLowerCase().includes(searchSubject.toLowerCase());
        } else {
            return (
                userData.subject.toLowerCase().includes(searchSubject.toLowerCase()) &&
                status[userData.id] === filter
            );
        }
    });

    return (
        <div>
            <div className="upperContainer">
                <div className="filterContainer">
                    <label htmlFor="status-filter">
                        <strong>Filter:</strong>
                    </label>
                    <select id="status-filter" value={filter} onChange={handleFilterChange}>
                        <option value="all">All</option>
                        <option value="unviewed">Backlog</option>
                        <option value="progress">InProgress</option>
                        <option value="review">Done</option>
                    </select>
                </div>
                <div className="searchInp">
                    <input
                        type="text"
                        placeholder="Search by Subject"
                        value={searchSubject}
                        onChange={handleSearchChange}
                    />
                </div>
            </div>
            <div className="cardContainer">
                {filteredUsers.map((userData) => (
                    <div className="userCard" key={userData.id}>
                        <div className="cardContent">
                            <div className="heading">
                                <h2>{userData.name}</h2>
                                <div className="selectContainer">
                                    <label htmlFor={`status-${userData.id}`}>
                                        <strong>Status:</strong>
                                    </label>
                                    <select
                                        id={`status-${userData.id}`}
                                        value={status[userData.id] || 'unviewed'}
                                        onChange={(event) => handleStatusChange(event, userData.id)}
                                    >
                                        <option value="unviewed">Backlog</option>
                                        <option value="progress">InProgress</option>
                                        <option value="review">Done</option>
                                    </select>
                                </div>
                            </div>
                            <hr />
                            <div className="userDetails">
                                <p>
                                    <strong>Mobile:</strong> {userData.mobile}
                                </p>
                                <p>
                                    <strong>Email:</strong> {userData.email}
                                </p>
                            </div>
                            <div className="subjectTime">
                                <p>
                                    <strong>Subject:</strong> {userData.subject}
                                </p>
                                <p>
                                    <strong>Time:</strong> {userData.time}
                                </p>
                            </div>
                            <p>
                                <strong>Description:</strong> {userData.description}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ViewCard;
