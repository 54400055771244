import React, {useState} from 'react';
import {auth} from '../../Firebase';
import ViewCard from './ViewCard/ViewCard';
import ViewTable from './ViewTable/ViewTable';
import './Home.css';
import {useAuth} from "../../AuthContext";

const Home = () => {
    const {logout} = useAuth();
    const [currentView, setCurrentView] = useState('card');

    const handleLogout = async () => {
        try {
            await auth.signOut();
            logout();
        } catch (error) {
            console.log('Error logging out:', error);
        }
    };

    const handleViewChange = (view) => {
        setCurrentView(view);
    };

    return (
        <div>
            <div className="top-section">
                <h1 className="heading">Feedback data</h1>
                <div className="view-dropdown">
                    <label htmlFor="view-select"><strong>View as:</strong></label>
                    <select
                        id="view-select"
                        value={currentView}
                        onChange={(e) => handleViewChange(e.target.value)}
                    >
                        <option value="card">Card</option>
                        <option value="table">Table</option>
                    </select>
                </div>
                <button className="logoutBtn" onClick={handleLogout}>
                    Logout
                </button>
            </div>
            {currentView === 'card' ? <ViewCard/> : <ViewTable/>}
        </div>
    );
};

export default Home;


// pagination, filter, 

//DONE: 1. view as card or table
//DONE: 2. search by subject
